import React, {useContext}  from 'react';
import { Logger }           from 'aws-amplify';
import { constants } from 'assets/Config.js';

import Divider              from 'pac-responsive-ui-framework/core/Divider';
import List                 from 'pac-responsive-ui-framework/core/MenuList';


import MenuItem             from 'pac-responsive-ui-framework/core/MenuItem';
import Menu                 from 'pac-responsive-ui-framework/core/Menu';

import AccountIcon          from '@material-ui/icons/PersonOutlined';
import SignoutIcon          from '@material-ui/icons/ExitToApp';
import HomeIcon           from '@material-ui/icons/Home';
//import { IconHome24 as HomeIcon }       from 'pac-ux-components';


import HelpIcon             from '@material-ui/icons/HelpOutline';
import FeedbackIcon         from '@material-ui/icons/FeedbackOutlined';
import FavoriteIcon       from '@material-ui/icons/StarBorderOutlined';
import DashboardIcon        from '@material-ui/icons/Dashboard';
import RecentIcon           from '@material-ui/icons/WatchLaterOutlined';
import FolderIcon           from '@material-ui/icons/FolderOutlined';
import LogViewIcon          from '@material-ui/icons/LibraryBooksOutlined';
import InfoIcon             from '@material-ui/icons/InfoOutlined';
import OperationsIcon       from '@material-ui/icons/Settings';

import AppContext           from 'pac-responsive-ui-framework/core/AppContext';
import { setReportList, getRecentReportList, getReportList, setFavoriteReports, getCategories }  from '../data/ReportList';

 
const logger = new Logger('NavigationMenu');

export default function NavigationMenu(props) {
  const app_context   = useContext(AppContext);  
  setReportList(app_context.state.reports_list);
  logger.debug("app_context", app_context);
  setReportList(app_context.state.reports_list);

  setFavoriteReports(app_context.state.favorite_reports);
   
  const RecentReportListData = getRecentReportList(app_context);
   
  const ReportListData = getReportList([], app_context.state);
  logger.debug("ReportListData", ReportListData);
  
  var favorite_reports = [];
  var not_favorite_reports = [];
  var all_reports = {'Dashboards': []};
  var secondary_reports = [];
    
  for(let i = 0 ; i < ReportListData.length; i++) {
    if (ReportListData[i].section != 'Dashboards' && !secondary_reports.includes(ReportListData[i].section)) {
      secondary_reports.push(ReportListData[i].section);
    }
  }
  secondary_reports.sort();
  logger.debug("Secondary_reports", secondary_reports);
  secondary_reports.forEach((val, index) => {
    all_reports[val] = [];
  });
  
  
  for(let i = 0 ; i < ReportListData.length; i++) {
    if (ReportListData[i].favorite) favorite_reports.push(ReportListData[i]);
    else not_favorite_reports.push(ReportListData[i]);
    if (ReportListData[i]['section']) {
      if (!all_reports[ReportListData[i]['section']])
        all_reports[ReportListData[i]['section']] = [];
      all_reports[ReportListData[i]['section']].push(ReportListData[i]);
    }
    else {
      all_reports['Dashboards'].push(ReportListData[i]);
    }
  }
  // bit of a hack because the reports come sorted with favorites first.
  // this does a true sort.
  for (const [key, value] of Object.entries(all_reports)) {
    all_reports[key].sort(
      function(a, b) {
        if (a['order'] && b['order'])
          return a['order'] > b['order'];
        else return 0;
      }
    )
  }
  
  all_reports['Dashboards'].sort(function(a, b) {
    return a['title'].toLowerCase().localeCompare(b['title'].toLowerCase());
  })
  
  /* moved to reportlist class
  // stolen from CardReport.js (should be moved to common class)
  var url_parameters = ''
  if (typeof app_context.state.default_operator_icao_code !== 'undefined') {
    var default_operator_icao_code = app_context.state.default_operator_icao_code
    if(typeof props.default_operator !== 'undefined'){
      default_operator_icao_code = props.default_operator
    }
    // removed as this is for open in new window     
    //url_parameters = "?Operator=" + default_operator_icao_code;
    //url_parameters = "?ts=" + new Date().valueOf();
  }   
  */
  
  function getMenuFromReportList(report_list, title, icon,  open = false, useCategory=false) {
    logger.debug('getMenuFromReportList', report_list);
    var report_jsx = '';
    if (report_list.length) {
     
      var menu_items = [];
      var categories = getCategories();
         
      for(let i = 0 ; i < report_list.length; i++) {
        var item_icon = <DashboardIcon/>
        //if (report_list[i].favorite) icon = <FavoriteIcon/>
        logger.debug('getMenuFromReportList', 'report_list', report_list[i].report_type, report_list[i]);
        
        var path = report_list[i].href;
        
        var text = report_list[i].title;
        if (report_list[i].target == '_blank') {
          text += ' ↗';
        }
        
        var menu_item = <MenuItem 
                            key={report_list[i].title} 
                            text={text} 
                            description={report_list[i].subtitle}
                            //icon={item_icon} 
                            target={report_list[i].target}
                            path={path}
                             />
       if (useCategory) {
         
         if (report_list[i].category) {
            if (!categories[report_list[i].category]) categories[report_list[i].category] = [];
            categories[report_list[i].category].push(menu_item); 
         }
         else {
           categories['General'].push(menu_item); 
         }
       }
       else {
          menu_items.push(menu_item);
        }
      }
      // loop through the categories and add the menus
      Object.keys(categories).forEach((key, index) => {
          if (categories[key].length) {
            menu_items.push(
              <Menu 
                  text={key} 
                  icon=<FolderIcon /> 
                  key={key} 
                  open={app_context.state.menus_open[key]} 
                  onClick={app_context.MenuHandleClick}
              >
                {categories[key]}
              </Menu>)
          }
      
      });
      
      report_jsx = (
         
            <Menu 
                text={title} 
                icon={icon} 
                key={title} 
                open={app_context.state.menus_open[title]} 
                onClick={app_context.MenuHandleClick}
            >
              {menu_items}
            </Menu>
          
         
        
      );
    }
    return report_jsx;
  }
  
  // set the report menus
  var report_menus = [];
  for (const [key, value] of Object.entries(all_reports)) {
    logger.debug("report_menu.push", key, value);
    var icon = <FolderIcon/>;
    if (key === "Dashboards") icon  = <DashboardIcon/>;
    else if (key.startsWith('Operations')) icon = <OperationsIcon/>;
    report_menus.push( getMenuFromReportList(value, key, icon, true, true));
  }
  
  // Add Demo menu items
  var dev_menu = null;
  if (process.env.REACT_APP_STAGE == 'dev' && constants.log_page) {
    dev_menu = 
      <React.Fragment>
        <Divider />
        <List>
          <MenuItem text="Logs" icon=<LogViewIcon/> path="/logview" />
        </List>
        
      </React.Fragment>
  }
  
  var profile_menu = null;
  if (constants.profile_page) {
    profile_menu = 
      <React.Fragment>
        <Divider />
        <List>
          <MenuItem text="Profile" icon=<AccountIcon/> path="/profile" />
        </List>
        
      </React.Fragment>
  }
  
  
  var more_menus;
  if (constants.faq_url || constants.feedback_url) {
    
    
    var faq;
    if (constants.faq_url) {
          faq=(
            <MenuItem text="FAQ ↗" icon=<HelpIcon/> target="_blank" path={{ pathname: constants.faq_url}} />
          )
    }
    
    var feedback;
    if (constants.feedback_url) {
       feedback=(
            <MenuItem text="Feedback" icon=<FeedbackIcon/> path={constants.feedback_url} />
          )
    }
    
    var about=(
        <MenuItem text={"About " + constants.title} icon=<InfoIcon/> path="/about" />
    )      
    
    
    more_menus = (
      <React.Fragment>
        <Divider />
        <List>
          {about}
            {faq}
            {feedback}
        </List>    
      </React.Fragment>
    )
  }
  
  return (
  
    
    <React.Fragment>   
      <List>
        <MenuItem text="Home" icon=<HomeIcon/> path="/" />
      </List>
      <Divider />
        {getMenuFromReportList(RecentReportListData, 'Recently Viewed', <RecentIcon/>, false)}
        {getMenuFromReportList(favorite_reports, 'Favorites', <FavoriteIcon/>, false)}
         <Divider />
        {report_menus}
        
     {dev_menu}
     {profile_menu}
     
      {more_menus}
      <Divider />
      <List>
        <MenuItem text="Signout" icon=<SignoutIcon/> path="/signout" />
      </List>
 </React.Fragment>   
   
  );
    
     
}