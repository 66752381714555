
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';

const logger = new Logger('ReportsService');

export default class ReportsService extends BaseService {

    constructor(apiName = "OriginsAPI", overwrite_title = true) {
        //let apiName = apiName; //'OriginsAPI';
        let collectionPath = '/reportconfig';
        super(apiName, collectionPath);
        this.overwrite_title = overwrite_title;
    }
    
    /**
     * This takes in the response object and returns the array data
     */
    formatRowData(response) {
        var data = response.config;
        logger.debug("formatRowData1", data);
        data.forEach((val, index) => {
            logger.debug("formatRowData1a", val['title'], val);
        
            if (val.dashboard_id_dev === undefined) {
                val.dashboard_id_dev = val.dashboard_id;
            }
            if (val.dashboard_id_test === undefined) {
                val.dashboard_id_test = val.dashboard_id; 
            }
            if (this.overwrite_title) {
                if (process.env.REACT_APP_STAGE == 'dev' && data[index]['alt_title_dev']) data[index]['title'] = data[index]['alt_title_dev'];
                if (process.env.REACT_APP_STAGE == 'test' && data[index]['alt_title_test']) data[index]['title'] = data[index]['alt_title_test'];
            }
            
            val.original_title = val.title;
            if (!val.section) val.section = "Dashboards";
            delete val.session; // old bad data
            
            if (typeof val.enabled === 'undefined') val.enabled = 1;
        
            data[index]['parameter_mapping'] = JSON.stringify(data[index]['parameter_mapping']);
            
            // self correcting bad or missing data
            if (Array.isArray(val.report_environments)) {
                if (val.report_environments.includes('dev')) {
                    data[index]['publish_dev'] = true;
                }
                if (val.report_environments.includes('test')) {
                    data[index]['publish_test'] = true;
                }
                if (val.report_environments.includes('prd')) {
                    data[index]['publish_prod'] = true;
                    data[index]['published'] = true;
                }
            }
            if (typeof val.tags === "string") {
                let t = val.tags.split(",");
                var new_tags = [];
                for (let i = 0; i < t.length; i++) {
                    new_tags.push(t[i].trim());
                }
                val.tags = new_tags;
            }
            if (!val['href']) val['href'] = "/dashboards/{title}";
        });
        logger.debug("formatRowData2", data);
        return data;
    }

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.config.length
        };
    }
    
    getCloneRecord(record, ) {
        const title_field = 'title';
        record[title_field] = record[title_field] + ' (Clone)';
        record['original_title'] = "";
        record['publish_dev'] = false;
        record['publish_test'] = false;
        record['publish_prod'] = false;
        record['published'] = false;
        return record;
    }
    
    
    async delete(data, recordID) {
        logger.debug("delete", data, recordID);
        try {
            var new_data = {reportid: recordID, config: []};
            var records = await this.fetch(recordID);
            logger.debug(records); 
            records.config.forEach((val, index) => {
                logger.debug(val, data); 
                if (val['title'] != data['title']) {
                    new_data.config.push(val);
                }
            });
            try {
                var results = await this.update(recordID, new_data);
                 
                logger.debug("delete service results", results);
                return { success: true, updated: true, results: results };
            }
            catch (err) {
                logger.debug("delete service err", err);
                return { success: false, updated: false, error: err };
            }
        }
        catch(err) {
            return { success: false, updated: false, error: err };
        }
    }
    
    async save(data, metaData) {
        logger.debug("save1", data, metaData);
        let yourDate = new Date();
        data['updated_date'] = yourDate.toISOString().substring(0, 19).replace("T", " ");
        data['updated_by'] = metaData.app_state.user.session.login_name; 
        
        data['report_environments']= [];
        if (data['publish_dev'])  {
            data['report_environments'].push('dev');
            delete data['publish_dev'];
        }
        if (data['publish_test']) {
            data['report_environments'].push('test');
            delete data['publish_test'];
        }
        if (data['publish']) {
            if (data['publish_prod']) { // unpublish
                // don't set the publish data
            }
            else {
                data['report_environments'].push('prd');
                data['published_date'] = yourDate.toISOString().substring(0, 19).replace("T", " ");
                data['published_by'] = metaData.app_state.user.session.login_name; 
            }
          
        }
        else if (data['publish_prod']) { 
             data['report_environments'].push('prd');
        }
        delete data['publish_prod'];
        delete data['publish'];
        delete data['published'];
      
        if ( typeof data.report_type === 'object') data.report_type = data.report_type.name.toString();
        
        // This could be converted using "storage_format" or something in the definitions
        
        if (data['assert_role']) {
            if (!Array.isArray(data['assert_role'])) {
                data['assert_role'] = data['assert_role'].split(",");
            }
            for(let i = 0; i < data['assert_role'].length; i++) {
                data['assert_role'][i] = data['assert_role'][i].trim();
            }
        }
        
        if (data['assert_icao']) {
            if (!Array.isArray(data['assert_icao'])) {
                data['assert_icao'] = data['assert_icao'].split(",");
            }
            for(let i = 0; i < data['assert_icao'].length; i++) {
                data['assert_icao'][i] = data['assert_icao'][i].trim();
            }
        }
        
        if (data['tags']) {
            if (!Array.isArray(data['tags'])) {
                data['tags'] = data['tags'].split(",");
            }
            for(let i = 0; i < data['tags'].length; i++) {
                data['tags'][i] = data['tags'][i].trim();
            }
        }
        
        logger.debug("save2", data);
        if (data['parameter_mapping']) {
            try {
                data['parameter_mapping'] = JSON.parse(data['parameter_mapping']);
            }
            catch (err) {
              //  logger.debug("save param_mapping error", err);
    
                return { 
                    success: false, 
                    updated: false, 
                    response: {
                        data: {
                            error: "Invalid JSON in Parameter Mapping", 
                            columns: [
                                {
                                    key: 'parameter_mapping',
                                    message: "Invalid JSON"
                                }
                            ]
                        
                        }
                    } 
                };
            }
        }
        logger.debug("save2", data);
       
        logger.debug("save3", data, metaData);
        
        try {
            var records = await this.fetch(metaData.recordID);
                     
            var new_data = {reportid: metaData.recordID, config: []};
            logger.debug("save service fetch records", metaData.recordID, records);
            // get max id
           
            var results;
            records.config.forEach((val, index) => {
                if (results) return; // break
                logger.debug("save records.config.forEach", data.original_title, data.title, val.title);
                
                if (data.original_title === val.title) { // update
                    logger.debug("save service updating");
                    new_data.config.push(data);
                }
                else if (data.original_title != data.title && data.title.toUpperCase()  == val.title.toUpperCase() ) {  //duplicate title
                    logger.debug("save service duplicate entry");
                    results = { 
                        success: false, 
                        updated: false, 
                        response: {
                            data: {
                                error: "Duplicate Title", 
                                columns: [
                                    {
                                        key: 'title',
                                        message: "Title already exists"
                                    }
                                ]
                            }
                        } 
                    };
                }
                else { // just setting the data if it is missing
                    logger.debug("save service adding non-saving entry");
                    
                    if ("enabled" in val === false) val.enabled = 1;               
                    new_data.config.push(val);
                }
            });
            if (results) return results;
            if (!data.original_title) {
                
                //logger.debug("save service inserting");
                new_data.config.push(data);
            }
            logger.debug("save new_data.config data", data);
            new_data.config.forEach((element) => {
                if (element['section'] === undefined) element['section'] = 'Dashboards';
                logger.debug("save new_data.config", element['section'], data['section'], element);
                if (element['section'] === data['section']) {
                    logger.debug("save new_data.config match", element['section']);
                    element['section_icon'] = data['section_icon'];
                    element['section_order'] = data['section_order'];
                    if (element['category'] === data['category']) {
                        element['category_icon'] = data['category_icon'];
                        element['category_order'] = data['category_order'];
                    }
                    
                }
            });
            
            logger.debug("save service new_data", metaData.recordID, new_data);
             
            try {
                var results = await this.update(metaData.recordID, new_data);
                 
                logger.debug("save service results", results);
                return { success: true, updated: true, results: results };
            }
            catch (err) {
                logger.debug("save service err", err);
                return { success: false, updated: false, error: err };
            }
            
        }
        catch (err) {
            logger.debug("save service fetch records failed", metaData.recordID, records);
            return { success: false, updated: false, error: err };
        }
    }
    
    

    getColumnDefs() {
        var column_width = 100;
        var columns =  [
            {
                headerName: 'History',
                defaultExpanded: false,
                children: [
                    {
                        field: "published",
                        headerName: "Published",
                        filter: 'agTextColumnFilter',
                        filterParams: { // this converts true to 1
                           valueGetter: (params) => {
                               logger.debug("valueGetter", params);
                               if (params.data['published']) return 1;
                               else return 0; 
                           }
                        },
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "hidden",
                        defaultValue: 0
                    },
                    {
                        field: "updated_date",
                        headerName: "Updated Date",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "readonly",
                        sort: "desc"
                    },
                      {
                        field: "updated_by",
                        headerName: "Updated By",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "readonly",
                        columnGroupShow: 'open'
                    },
                     
                    {
                        field: "published_date",
                        headerName: "Published Date",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "readonly",
                        columnGroupShow: 'open',
                        newline: true,
                    },
                    {
                        field: "published_by",
                        headerName: "Published By",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "readonly",
                        
                        columnGroupShow: 'open'
                    },
                   
                ]
            },
            {
                headerName: 'Details',
                defaultExpanded: true,
                children: [
                    {
                        field: "original_title",
                        headerName: "ID",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        hide: true, //hides from the grid
                        readonly: true,
                        formType: "hidden", // hides in the form
                    },
                    {
                        field: "enabled",
                        headerName: "Enabled",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        defaultValue: 0,
                    },
                    {
                        field: "report_type",
                        headerName: "Report Type",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        key: "report_type", // set to avoid dup keys (value is irrelevent)
                        required: true,
                        formType: "select",
                        options: [
                            "quicksight",
                            "kibana",
                            "powerbi",
                            "insights",
                            "php",
                            "internal"
                        ],
                        getOptionLabel: (option) => { return option },
                        renderOption: (option) =>  { return option },
                        getValue: (data) => { 
                            return data.report_type;
                        },
                        newline: true
                    },
                    {
                        field: "",
                        headerName: "",
                        filter: 'agTextColumnFilter',
                        width: column_width *2,
                        hide: true,
                        resizable: true,
                        formType: "content",
                        required: false,
                        newline: true,
                        formContent: "The order value will determine the order of the item in the menu.  If no order is defined, it will be alphabetical."
                    },
                    /*
                    {
                        field: "",
                        headerName: "",
                        filter: 'agTextColumnFilter',
                        width: column_width *2,
                        hide: true,
                        resizable: true,
                        formType: "content",
                        required: false,
                        newline: true,
                        formContent: "Changes to the order or icon for a category or section will impact all records with that same name."
                    },
                    {
                        field: "",
                        headerName: "",
                        filter: 'agTextColumnFilter',
                        width: column_width *2,
                        hide: true,
                        resizable: true,
                        formType: "content",
                        required: false,
                        newline: true,
                        formContent: "Icon names are lowercase and spaces should be underscores."
                    },
                    */
                    
                    {
                        field: "section_order",
                        headerName: "Order",
                        filter: 'agTextColumnFilter',
                        width: column_width /2,
                        resizable: true,
                        formType: "text",
                        newline: true,
                        required: false,
                    },
                    {
                        field: "section",
                        headerName: "Section",
                        filter: 'agTextColumnFilter',
                        width: column_width *2,
                        resizable: true,
                        formType: "text",
                        required: true,
                    },
                    {
                        field: "section_icon",
                        headerName: "Section Icon",
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        resizable: true,
                        formType: "icon",
                        required: false,
                       
                    },
                    {
                        field: "category_order",
                        headerName: "Order",
                        filter: 'agTextColumnFilter',
                        width: column_width /2,
                        resizable: true,
                        formType: "text",
                        required: false,
                        newline: true,
                    },
                    {
                        field: "category",
                        headerName: "Category",
                        filter: 'agTextColumnFilter',
                        width: column_width *2,
                        resizable: true,
                        formType: "text",
                        required: true
                    },
                    {
                        field: "category_icon",
                        headerName: "Category Icon",
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        resizable: true,
                        formType: "icon",
                        required: false
                    },
                   
                    {
                        field: "title_order",
                        headerName: "Order",
                        filter: 'agTextColumnFilter',
                        width: column_width /2,
                        resizable: true,
                        formType: "text",
                        required: false,
                        newline: true,
                    },
                    {
                        field: "title",
                        headerName: "Title",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        required: false,
                        recordName: true,
                        helperText: "Leave blank to have the report link at the category level.",
              
                    },
                    {
                        field: "title_icon",
                        headerName: "Title Icon",
                        filter: 'agTextColumnFilter',
                        width: column_width *1.5,
                        resizable: true,
                        formType: "icon",
                        required: false
                    },
                    {
                        field: "subtitle",
                        headerName: "Subtitle",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "multiline",
                        newline: true
                    },
                    {
                        field: "href",
                        headerName: "Page URL",
                        headerTooltip: "The path of the report within the application",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "text",
                        required: true,
                        newline: true
                    },
                    {
                        field: "report_href",
                        headerName: "Report URL",
                        headerTooltip: "The path of the embedded report",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "text",
                        newline: true
                    },
                    {
                        field: "docs_href",
                        headerName: "Docs URL",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "text",
                        newline: true
                    },
                    {
                        field: "image",
                        headerName: "Image",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text"
                    },
                    {
                        field: "tags",
                        headerName: "Tags",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "E.g.: kpi, usage, media"
                    },
                ]
            },
            {
                headerName: 'Report Parameters',
                defaultExpanded: true,
                children: [
                    {
                        field: "dashboard_id",
                        headerName: "Dashboard ID (Prod)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "(QuickSight reports only)",
                        newline: true
                    },
                    {
                        width: column_width * 3,
                        resizable: true,
                        formType: "anchor",
                        href: (value) => {  
                            logger.debug('href column type', value);
                            if (value['report_type'] === "quicksight" && value['dashboard_id'] !== undefined &&  value['dashboard_id'] !== "") return "https://us-west-2.quicksight.aws.amazon.com/sn/dashboards/" + value['dashboard_id'];
                            else return null;
                        },
                        label: "Launch the dashboard in AWS Quicksight.",
                        target: "_blank",
                        hide: true
                    },
                    {
                        field: "dashboard_id_test",
                        headerName: "Dashboard ID (Test)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "(QuickSight reports only)",
                        newline: true,
                        columnGroupShow: "open"
                    },
                    {
                        width: column_width * 3,
                        resizable: true,
                        formType: "anchor",
                        href: (value) => {  
                            logger.debug('href column type', value);
                            if (value['report_type'] === "quicksight" && value['dashboard_id_test'] !== undefined &&  value['dashboard_id_test'] !== "") return "https://us-west-2.quicksight.aws.amazon.com/sn/dashboards/" + value['dashboard_id_test'];
                            else return null;
                        },
                        label: "Launch the dashboard in AWS Quicksight.",
                        target: "_blank",
                        hide: true
                      
                    },
                    {
                        field: "alt_title_test",
                        headerName: "Alternative Title (Test)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "text",
                        newline: true,
                        columnGroupShow: "open"
                    },
                    {
                        field: "dashboard_id_dev",
                        headerName: "Dashboard ID (Dev)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "(QuickSight reports only)",
                        newline: true,
                        columnGroupShow: "open"
                    },
                    {
                        width: column_width * 3,
                        resizable: true,
                        formType: "anchor",
                        href: (value) => {  
                            logger.debug('href column type', value);
                            if (value['report_type'] === "quicksight" && value['dashboard_id_dev'] !== undefined && value['dashboard_id_dev'] !== "") return "https://us-west-2.quicksight.aws.amazon.com/sn/dashboards/" + value['dashboard_id_dev'];
                            else return null;
                        },
                        label: "Launch the dashboard in AWS Quicksight.",
                        target: "_blank",
                        hide: true
                    },
                    {
                        field: "alt_title_dev",
                        headerName: "Alternative Title (Dev)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "text",
                        newline: true,
                        columnGroupShow: "open"
                    },
                    {
                        field: "parameter_mapping",
                        headerName: "Parameter Mapping",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "Must be valid JSON.  Should map the following values: operator, start, end. E.g.: {\"operator\":\"IcaoAirline\"}",
                        newline: true
                    },
                    {
                        field: "start",
                        headerName: "Start",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        helperText: "E.g.: now | now-1d | now+30d"
                    },
                    {
                        field: "end",
                        headerName: "End",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        helperText: "E.g.: now | now-1d | now+30d"
                    }
                ]
            },
            {
                headerName: 'Access Control',
                defaultExpanded: true,
                children: [
                    {
                        field: "publish_prod",
                        headerName: "Prod",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        filterParams: { // this converts true to 1
                            valueGetter: (params) => {
                               logger.debug("valueGetter", params);
                               if (params.data['publish_prod']) return 1;
                               else return 0; 
                           }
                        },
                        defaultValue: 0,
                        readonly: true
                    },
                    {
                        field: "publish_test",
                        headerName: "Test",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        filterParams: { // this converts true to 1
                           valueGetter: (params) => {
                               logger.debug("valueGetter", params);
                               if (params.data['publish_test']) return 1;
                               else return 0; 
                           }
                        },
                        defaultValue: 0,
                    },
                    {
                        field: "publish_dev",
                        headerName: "Dev",
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        formType: "boolean",
                        filterParams: { // this converts true to 1
                           valueGetter: (params) => {
                               logger.debug("valueGetter", params);
                               if (params.data['publish_dev']) return 1;
                               else return 0; 
                           }
                        },
                        defaultValue: 0,
                    },
                    {
                        width: column_width,
                        hide: true,
                        formType: "content",
                        formContent: "Note: The Prod setting is not set here, but through Production Gate.", 
                        newline: true
                    },
                    /*
                    {
                        field: "report_environments",
                        headerName: "Environments",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
    
                        formType: "multiselect",
                        options: ['dev', 'test', 'prod'],
                        getOptionLabel: (option) => { return option },
                        getInitValue: (data, options) => {
                            return data.report_environments;
                        },
                        renderOption: (option) => { return option },
                        getValue: (data) => { return data['report_environments'] }
                    },
                    */
                    {
                        field: "assert_icao",
                        headerName: "Assert ICAO Code",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "(Customers Only) E.g.: AAL,BAW,CPA,EIN,FIN",
                        newline: true
                    },
                    {
                        field: "assert_role",
                        headerName: "Assert Role",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        helperText: "E.g.: commercial,pac-cloud-and-data-engineering,pac-mobility-services"
                    }
                ]
            },
            
            {
                headerName: 'MetaData',
                defaultExpanded: false,
                children: [
                     {
                        field: "team",
                        headerName: "Team",
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        resizable: true,
                        formType: "text"
                    },
                    {
                        field: "priority",
                        headerName: "Priority",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text"
                    },
                    {
                        field: "notes",
                        headerName: "Notes 1",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "multiline"
                    },
                    {
                        field: "notes2",
                        headerName: "Notes 2",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "multiline"
                    },
                    {
                        field: "notes3",
                        headerName: "Notes 3",
                        filter: 'agTextColumnFilter',
                        width: column_width * 4,
                        resizable: true,
                        formType: "multiline"
                    },
                ]
            }
        ];
        if (this.permissions.includes('p')) {
            columns.push({
                headerName: 'Production Gate',
                defaultExpanded: true,
                children: [
                    {
                        field: "publish",
                        headerName: (value) => {
                            logger.debug("column", value);
                            if (value['publish_prod'] === true) 
                                return "Unpublish to production";
                            else 
                                return "Publish to production";
                        },
                        filter: 'agTextColumnFilter',
                        cellRenderer: BooleanCellRenderer,
                        width: column_width,
                        hide: true,
                        formType: "boolean",
                        defaultValue: 0,
                        publisher: true,
                    },
                    {
                        //field: "foo",
                        //headerName: "Confluence URL",
                        //filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        resizable: true,
                        formType: "anchor",
                        publisher: true,
                        href: (value) => {  
                            logger.debug('href column type', value);
                            if (value['report_type'] === "quicksight" && value['dashboard_id'] !== "") return "https://us-west-2.quicksight.aws.amazon.com/sn/dashboards/" + value['dashboard_id'];
                            else return null;
                        },
                        label: "Launch the dashboard in AWS Quicksight.",
                        target: "_blank",
                        hide: true,
                        newline: true
                    },
                    {
                        field: "publish_url",
                        headerName: "Confluence URL",
                        filter: 'agTextColumnFilter',
                        width: column_width * 3,
                        resizable: true,
                        formType: "text",
                        publisher: true,
                        hide: true,
                        newline: true
                    },
                ]
            });
        }
        /*
        columns.push({
            headerName: 'History',
            defaultExpanded: false,
            children: [
                 {
                    field: "updated_by",
                    headerName: "Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly"
                },
                {
                    field: "updated_date",
                    headerName: "Updated Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly"
                },
                 {
                    field: "published_by",
                    headerName: "Published By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true
                },
                {
                    field: "published_date",
                    headerName: "Published Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly"
                },
                {
                    field: "published",
                    headerName: "Published",
                  
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "hidden",
                    defaultValue: 0,
                    ide: true,
                }
            ]
        });
        */
        
        return columns;
    }
}