
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

import AppContext               from 'pac-responsive-ui-framework/core/AppContext';

import dateMath from '@elastic/datemath';

import url from "../util/URL";

//import Checkbox from '@material-ui/core/Checkbox';
//import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';


import { Logger } from 'aws-amplify';
const logger = new Logger('components/QuickSight');

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function QuickSight(props) {
    logger.debug("props", props);
    const classes = useStyles();
    var app_context = React.useContext(AppContext);

    const dashboardRef = useRef([]);
    //const [dashboardId, setDashboardId] = useState(props.report_details.dashboard_id);
    const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
    //const [dashboardUrl, setDashboardUrl] = useState(props.report_details.report_href);
    const [embeddingContext, setEmbeddingContext] = useState(null);
    const [initialized, setInitialized] = useState(0);
    const [initParameters, setInitParameters] = useState([]);
    // so I can track their changes and know if I should overwrite the URL
    const [contextOperator, setContextOperator] = useState(app_context.state.default_operator_icao_code);
    const [contextStartDate, setContextStartDate] = useState(app_context.state.start_date);
    const [contextEndDate, setContextEndDate] = useState(app_context.state.end_date);


    //const [footer, setFooter] = useState(null);

    //const [pUpdateMap, setPUpdateMap] = useState(false);

    /**
     * Step 1B: Create the context object and set it into the state
     */
    const embedContext = async () => {
        QuickSightEmbedding.createEmbeddingContext()
        .then (function(context) {
            setEmbeddingContext(context);
        });
    };

    /**
     * Step1A: start the process by createing the embedding context object.
     */
    useEffect(() => {
        //logger.debug("useEffect 1A 1");
        if (!embeddingContext) {
            //logger.debug("useEffect 1A 2");

            embedContext();
        }
    });

    const getDate = (date_string, roundup = false) => {
        date_string = date_string.replace(/\s+/g, ''); // remove all white space

        logger.debug("getAppParameters1 getDate", date_string);
        var date;
        if (date_string) {
            var moment = dateMath.parse(date_string);

            if (moment.isValid()) {
                date = moment.format('YYYY-MM-DD');
                if (roundup) date += " 23:59:59 GMT+0000";
                else date += " 00:00:00 GMT+0000";
                date = Date.parse( date );
                //date = date.replace('T', ' ');
                //date = date + ' GMT+0000';
                logger.debug("getAppParameters1 Date", date_string, date, dateMath.parse(date_string).valueOf());
            }
        }
        return date;
    };

    /**
     * This creates a parameter list from the app_context
     * This is used to push the default values to the contentOptions
     */
    const setAppParameters = () => {
        logger.debug("setAppParameters1 props", props );
        var parameters = [];


        var parameter_mapping = props.report_details.parameter_mapping;
        logger.debug("setAppParameters1 parameter_mapping", parameter_mapping );

        var start;
        var end;
        if (props.report_details.start) {
            start = getDate(props.report_details.start);
            parameters.push({Name: "start", Values: [start]});
        }
        if (props.report_details.end) {
            end  = getDate(props.report_details.end, true);
            parameters.push({Name: "end", Values: [end]});
        }

        logger.debug("setAppParameters1 dates", start, end );

        // application  params
        if (app_context.state.default_operator_icao_code && app_context.state.default_operator_icao_code !== '---' ) {
            parameters.push({Name: "operator", Values: [app_context.state.default_operator_icao_code]});
        }
        if (app_context.state.start_date && app_context.state.end_date) {
            parameters.push({Name: "start", Values: [app_context.state.start_date]});
            parameters.push({Name: "end", Values: [app_context.state.end_date]});
        }


        if ('parameter_mapping' in props.report_details && typeof props.report_details.parameter_mapping == 'object'){
            var new_parameters = [];
            parameters.forEach((param) => {
                if(param.Name in props.report_details.parameter_mapping){
                    new_parameters.push({Name: props.report_details.parameter_mapping[param.Name], Values: param.Values});
                }
                else {
                    new_parameters.push({Name: param.Name, Values: param.Values});
                }
            });
            logger.debug("setAppParameters1 new_parameters", parameters, new_parameters );
            parameters = new_parameters;
        }


         logger.debug("setAppParameters1 final parameters1", parameters);

            // url params
        const params = Array.from(new URLSearchParams(window.location.search));
        logger.debug("getAppParameters1 url params", params );
        var keys = [];
        params.forEach((param) => {
            logger.debug("getAppParameters2", param);
            if (param[0] == "selected_sheet") {
                embeddedDashboard.setSelectedSheetId(param[1]);
            }
            else {
                var found = false;
                parameters.forEach((p) => {
                    if (p.Name === param[0]) {
                        if (!found) p.Values = []; // reset the parameter value
                        found = true;
                        p.Values.push(param[1].split(","));
                    }
                });
                if (!found) {
                    keys.push(param[0]);
                    parameters.push({Name: param[0], Values: param[1].split(",")});
                }
            }
        });
        logger.debug("setAppParameters1 final parameters2", parameters);
        app_context.updateQuickSightParameter(parameters);
        embeddedDashboard.setParameters(parameters);
        setInitialized(3);
        return;
    };

    /**
     * Step 2B: create the dashboard and container objects
     */
    const embedDashboard = async () => {
        logger.debug("embedDashboard", props.report_details.embed_url);

        const frameOptions = {
            url: props.embed_url,
            container: dashboardRef.current,
            height: "100%",
            width: "100%",
            withIframePlaceholder: true,
            onChange: (changeEvent, metadata) => {
                logger.debug("frameOptions onChange", changeEvent, metadata);
            }
        };

        var contentOptions = {
            toolbarOptions: {
                export: true,
                undoRedo: true,
                reset: true
            },
            onMessage: async (messageEvent, experienceMetadata) => {
                logger.debug("messageEvent", messageEvent.eventName, messageEvent, experienceMetadata);
                if (messageEvent.eventName === "PARAMETERS_CHANGED") {
                    //var updateParameter = true;
                    messageEvent.message.changedParameters.forEach((param) =>{
                        logger.debug("quicksight_parameters PARAMETERS_CHANGED", param.Name, param.Values.toString());

                    });
                    //if (updateParameter)
                    app_context.updateQuickSightParameter(messageEvent.message.changedParameters);
                }
                else if (messageEvent.eventName === "SELECTED_SHEET_CHANGED") {
                    app_context.updateQuickSightParameter([{Name:"selected_sheet", Values: [messageEvent.message.selectedSheet.SheetId]}]);
                }
                else if (messageEvent.eventName === "EXPERIENCE_INITIALIZED") {
                    setInitialized(1);
                }
                else if (messageEvent.eventName === "CONTENT_LOADED") {

                    setInitialized(2);
                }
            }
        };
        /*
        var parameters = getAppParameters();
        logger.debug("AppParameters", parameters);
        logger.debug("contentOptions", contentOptions);
         */

        embeddingContext.embedDashboard(frameOptions, contentOptions)
        .then(function(dashboard) {
            logger.debug("embedDashboard", embedDashboard);
            setEmbeddedDashboard(dashboard);

        });
    };

    /**
     * Set the initial parameter values
     */
    const setParameters = async () => {
        if (embeddedDashboard) {
            embeddedDashboard.getParameters()
            .then(function(params) {
                logger.debug("quicksight_parameters setParameters2", params);
                setInitParameters(params);
            }).catch(e => {
                logger.debug("setParameters error", e);
            });
        }
    };

    /**
     *  Step2A:  create the embed Dashboard.
     */
    useEffect(() => {
        //logger.debug("useEffect dashboardUrl", props.report_details.embed_url);
        if (embeddingContext) {
            embedDashboard() }
    }, [embeddingContext]);

    useEffect(() => {
        //logger.debug("useEffect [initialized, embeddedDashboard]", initialized, embeddedDashboard);
        if (embeddedDashboard && initialized == 1) {
            //setInitParameters();
            setParameters();
           /*
           var parameters = getAppParameters();
            logger.debug("useEffect parameters", parameters);
            if (parameters.length) {
                embeddedDashboard.setParameters(parameters);
            }
            */
            //setInitialized(2);
        }
        else if (embeddedDashboard && initialized == 2) {
            setAppParameters();
        }
        else if (!embeddedDashboard && !initialized) {
            //logger.debug("useEffect [initialized, embeddedDashboard] unset", initialized, embeddedDashboard);
            app_context.updateQuickSightParameter([], true);
        }

    }, [initialized, embeddedDashboard]);

    /**
     * Returns only the parameters that have changed.
     */
    const getModifiedParameters = () => {
        return app_context.state.quicksight_parameters;
    };

    /**
     * Get the full parameter list initParameters combined with modified Parameters
     */
     /*
    const getParameters = () => {
        logger.debug("getParameters", initParameters, app_context.state.quicksight_parameters);
        var params = initParameters;
        app_context.state.quicksight_parameters.forEach((n) => {
            var found = false;
            params.forEach((p) => {
                if (n.Name === p.Name) {
                    p.Values = n.Values;
                    found = true;
                }
            });
            if (!found) params.push(n);
        });
        return params;
    };
    */

    /*
    const getMapURL = (params) => {
        var url = "https://ea931ad80de546668793c48b0380317c.vfs.cloud9.us-west-2.amazonaws.com/flightmap";
        var query_string_array = ["live=" + live, "update_map=true"];



        app_context.state.quicksight_parameters.forEach((param) => {
            if(param.Name === "pOperator") {
                query_string_array.push("operator=" + param.Values.join(","));
            }
            else if(param.Name === "pFlightID") {
                query_string_array.push("flight_id=" + param.Values.join(","));
            }
            else if(param.Name === "pTail") {
                query_string_array.push("tail=" + param.Values.join(","));
            }
            else if(param.Name === "pRoute") {
                query_string_array.push("route=" + param.Values.join(","));
            }
            else if(param.Name === "pTailActionRequest") {
                query_string_array.push("tail_action_request=" + param.Values.join(","));
            }
            else if(param.Name === "pRouteActionRequest") {
                query_string_array.push("route_action_request=" + param.Values.join(","));
            }
            //else if(param.Name === "") {
            //    query_string_array.push("flight_count=" + param.Values.join(","));
            //}
            else if(param.Name === "pUpdateMap") {
                query_string_array.push("update_map=" + param.Values.join(","));
            }
            //else if(param.Name === "") {
            //    query_string_array.push("maxSLA=" + param.Values.join(","));
            //}
            //else if(param.Name === "") {
            //    query_string_array.push("url=" + param.Values.join(","));
            //}
        });

        logger.debug("getMapURL3", query_string_array);
        //?operator=AAL
        //&flight_id=All
        //&tail=N799AN
        //&route=All
        //&tail_action_request=1
        //&route_action_request=0
        //&flight_count=5
        //&update_map=yes
        //&maxSLA=100
        //&url=http://origins.nextcloud.aero/dashboards/FlightTracker&param_map=%7B%22flight_id%22:%22pFlightIDMap%22,%20%22operator%22:%22pOperator%22,%20%22route%22:%22pRouteMap%22,%20%22tail%22:%22pTailMap%22,%22tail_action_request%22:%22pTailActionRequest%22,%22route_action_request%22:%22pRouteActionRequest%22,%22maxSLA%22:%20%22pFromSLA%22%7D";
        if (query_string_array.length > 0) url += "?" + query_string_array.join("&");
        logger.debug("getMapURL4", url);
        return url;
    };
    */

   /*
    const onClickMap = (e) => {
        openMap(false);

    };

    const onClickLiveMap = (e) => {
        openMap(true);
    };
    */

    const onClickReset = (e) => {

    };

    const openMap = (params) => {
        var href = window.location.origin + '/flightmap?' + params.join("&");
        //href = "http://origins.nextcloud.aero/flightmap?" + params.join("&"); // for testing

        var winref = window.open("", "_blank");
        logger.debug("openMap", winref);
        winref.location.href = href;

        /*
        var winref;
        if (live) {
            winref = window.open("", "origins_map");
            setWinref(winref);
            if(winref.location.href === 'about:blank') {
                winref.location.href = getMapURL(true);
            }
        }
        else {
            winref = window.open("", "_blank");
            winref.location.href = getMapURL(false);
        }
        */
    };

    /*
    const refreshMap = () => {
        //var winref = window.open("", "origins_map");
        //logger.debug("refreshMap", winref);
        if (winref)
            winref.location.href = getMapURL(true);
    };
    */

    // see if the map needs to be opened
    //var table_action = null;
    var map_param_values = {};
    //var map_params = ["pTableActionCode", "pRouteMap", "pFlightIDMap", "pTailMap", "pOperator"];
    getModifiedParameters().forEach((param) => {
        /*
        logger.debug("modified_parameters", param);
        if (param.Name === "pTableActionCode" && param.Values.toString()  === "SINGLE_FLIGHT") {
            table_action = "SINGLE_FLIGHT";
        }
        else   if (param.Name === "pTableActionCode" && param.Values.toString()  === "RECENT_FLIGHTS_BY_TAIL") {
            table_action = "RECENT_FLIGHTS_BY_TAIL";
        }
         else   if (param.Name === "pTableActionCode" && param.Values.toString()  === "RECENT_FLIGHTS_BY_ROUTE") {
            table_action = "RECENT_FLIGHTS_BY_ROUTE";
        }
        else if (map_params.includes(param.Name)) {
            map_param_values[param.Name] = param.Values.toString();
        }
        */
        map_param_values[param.Name] = param.Values.toString();
    });
    logger.debug("map_parameters", map_param_values);

    var map_query_string_array;
    if (map_param_values['pTableActionCode'] === "SINGLE_FLIGHT") {
        if (map_param_values.pMapFlightID && map_param_values.pMapTail) {
            map_query_string_array = [];
            map_query_string_array.push("type=Single Flight");
            map_query_string_array.push("operator=" + map_param_values.pMapOperator);
            map_query_string_array.push("route=" + map_param_values.pMapRoute);
            map_query_string_array.push("tail=" + map_param_values.pMapTail);
            map_query_string_array.push("flight_id=" + map_param_values.pMapFlightID);
            if (map_param_values.pMapTakeoff) {
                var date = new Date(map_param_values.pMapTakeoff).toISOString().slice(0,16).replace("T", " ");
                map_query_string_array.push("takeoff_time=" + date);
            }
            map_query_string_array.push("tail_action_request=0");
            map_query_string_array.push("route_action_request=0");
            map_query_string_array.push("flight_count=1");



            openMap(map_query_string_array);
            embeddedDashboard.setParameters([
                { Name: 'pTableActionCode', Values: null },
                { Name: 'pMapRoute', Values: null },
                { Name: 'pMapTail', Values: null },
                { Name: 'pMapFlightID', Values: null },
                { Name: 'pMapFlightCount', Values: null },
                { Name: 'pMapTakeoff', Values: null }
            ]);
        }
    }
    else if (map_param_values['pTableActionCode'] === "RECENT_FLIGHTS_BY_TAIL") {
       if (map_param_values.pMapFlightID && map_param_values.pMapTail) {
            var flight_count = map_param_values.pMapFlightCount?map_param_values.pMapFlightCount:6;

            map_query_string_array = [];
            map_query_string_array.push("type=Recent Flights by Tail");
            map_query_string_array.push("operator=" + map_param_values.pMapOperator);
            map_query_string_array.push("tail=" + map_param_values.pMapTail);
            map_query_string_array.push("tail_action_request=1");
            map_query_string_array.push("route_action_request=0");
            map_query_string_array.push("flight_count=" + flight_count);

            openMap(map_query_string_array);
            embeddedDashboard.setParameters([
                { Name: 'pTableActionCode', Values: null },
                { Name: 'pMapRoute', Values: null },
                { Name: 'pMapTail', Values: null },
                { Name: 'pMapFlightID', Values: null },
                { Name: 'pMapFlightCount', Values: null },
                { Name: 'pMapTakeoff', Values: null }
            ]);
        }
    }
    else if (map_param_values['pTableActionCode'] === "RECENT_FLIGHTS_BY_ROUTE") {
        if (map_param_values.pMapFlightID && map_param_values.pMapRoute) {
            flight_count = map_param_values.pMapFlightCount?map_param_values.pMapFlightCount:6;

            map_query_string_array = [];
            map_query_string_array.push("type=Recent Flights by Route");
            map_query_string_array.push("operator=" + map_param_values.pMapOperator);
            map_query_string_array.push("route=" + map_param_values.pMapRoute);
            map_query_string_array.push("tail_action_request=0");
            map_query_string_array.push("route_action_request=1");
            map_query_string_array.push("flight_count=" + flight_count);

            openMap(map_query_string_array);
            embeddedDashboard.setParameters([
                { Name: 'pTableActionCode', Values: null },
                { Name: 'pMapRoute', Values: null },
                { Name: 'pMapTail', Values: null },
                { Name: 'pMapFlightID', Values: null },
                { Name: 'pMapFlightCount', Values: null },
                { Name: 'pMapTakeoff', Values: null }
            ]);
        }
    }

    var footer;
    if (0 && dashboardRef && dashboardRef.current && dashboardRef.current.style) {
        footer = (
            <div style={{height: "72px", paddingLeft: "16px", paddingTop: "6px"}} >
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<img height="24" width="24" src="/images/map24x24.png" />}
                    onClick= {onClickReset}
                 >
                    Reset Filters
                </Button>
           </div>
        );
        dashboardRef.current.style.height = "calc(100% - 72px)";
    }


    /*
    getParameters().forEach((param) => {


        if (param.Name === "pUpdateMap" && dashboardRef && dashboardRef.current && dashboardRef.current.style) {
            logger.debug("dashboardRef.current", dashboardRef.current);
            dashboardRef.current.style.height = "calc(100% - 72px)";
            footer = (
                <div style={{height: "72px", paddingLeft: "16px", paddingTop: "6px"}} >
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<img height="24" width="24" src="/images/map24x24.png" />}
                        onClick= {onClickLiveMap}
                     >
                        Live Map
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<img height="24" width="24" src="/images/map24x24-2.png" />}
                        onClick= {onClickMap}
                     >
                        Fixed Map
                    </Button>
                    <FormControlLabel control={
                        <Checkbox
                            name="pUpdateMap"
                            checked={pUpdateMap}
                            onChange={ (event) => {
                                logger.debug("pUpdateMap.onChange", event);
                                embeddedDashboard.setParameters([{
                                    Name: 'pUpdateMap',
                                    Values: event.target.checked?"yes":"no"
                                }]);
                                setPUpdateMap(event.target.checked);
                            }}
                        />

                    } label="Auto-update Map" />
                </div>
            );
        }
    });
    */
    if (initialized == 3) {

        var parameters = [];

        if (app_context.state.default_operator_icao_code != contextOperator && app_context.state.default_operator_icao_code && app_context.state.default_operator_icao_code !== '---' ) {

            parameters.push({Name: "operator", Values: [app_context.state.default_operator_icao_code]});
            setContextOperator(app_context.state.default_operator_icao_code);
        }
        if ((app_context.state.start_date != contextStartDate || app_context.state.start_date != contextStartDate) && app_context.state.start_date && app_context.state.end_date) {
            parameters.push({Name: "start", Values: [app_context.state.start_date]});
            parameters.push({Name: "end", Values: [app_context.state.end_date]});
            setContextStartDate(app_context.state.start_date);
            setContextEndDate(app_context.state.end_date);
        }

        if ('parameter_mapping' in props.report_details && typeof props.report_details.parameter_mapping == 'object'){
            var new_parameters = [];
            parameters.forEach((param) => {
                if(param.Name in props.report_details.parameter_mapping){
                    new_parameters.push({Name: props.report_details.parameter_mapping[param.Name], Values: param.Values});
                }
                else {
                    new_parameters.push({Name: param.Name, Values: param.Values});
                }
            });
            logger.debug("setAppParameters1 new_parameters", parameters, new_parameters );
            parameters = new_parameters;
        }

        logger.debug("initialized3", parameters);
        embeddedDashboard.setParameters(parameters);


        // set query string to changed parameters
        var query_string_array = []; //"live=" + live, "update_map=true"];

        logger.debug("initialized3",getModifiedParameters());

        // remove any parameter set to "All" from the URL
        getModifiedParameters().forEach((param) => {
            if (param.Values.length === 1 && param.Values[0] === "All") {
                
            }
            else if (param.Values.length === 0) {
                query_string_array.push(param.Name + "= ");
            }
            else query_string_array.push(param.Name + "=" + param.Values.join(","));
        });


        /*
        getModifiedParameters().forEach((param) => {
            var found = false;
            parameters.forEach((p) => {
                if (param.Name == p.Name) found = true;
            });
            if (!found) query_string_array.push(param.Name + "=" +  param.Values.join(","));
        });
        parameters.forEach((param) => {
            query_string_array.push(param.Name + "=" + param.Values.join(","));
        });

        logger.debug("initialized3",query_string_array);
        */

        var query_string = "";
        if (query_string_array.length)
            query_string = "?" + query_string_array.join('&');
        url.pushState("", null, query_string, app_context.state.page_title);
    }
    return (
        <React.Fragment>
            <div style={{height: "100%", overflow: "none"}} ref={dashboardRef} />
            {footer}

        </React.Fragment>
    );
}