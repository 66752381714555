import PageVisitService   from '../services/PageVisitService';
import { constants } from '../assets/Config.js';
const page_visit_service = new PageVisitService();


export default class URL {


    /**
     * This emulates windows.history.pushState but adds a parameter of page_title
     */
    static pushState(state, unused, url, page_title, log_as_page_visit = true) {

        var current_url = window.location.search;

        let current_params = new URLSearchParams(current_url);
        let new_params = new URLSearchParams(url);
        let state_change = [];
        console.log('pushState urls', current_url, url);
        for (const p of new_params) {
            if (current_params.has(p[0])) {
                console.log('pushState0a', p[0], p[1], current_params.get(p[0]) );
                if (p[1] != current_params.get(p[0]) ) {
                    state_change.push(p[0] + '=' + p[1]);
                }

            }
            else {
                console.log('pushState0b', p[0] );
                state_change.push(p[0] + '=' + p[1]);
            }
        }
        console.log('pushState1', state_change);
        window.history.pushState(state, unused, url);

        let path = window.location.href.split('?')[0];

        if (!url.startsWith(path)) { // append the start of the URL if it is only a query string
            url = path + url;
        }

        console.log("pushState2", {
            report_name: page_title,
            report_url: url,
             app_name: constants.app,
             //state_change:
        });
        if (log_as_page_visit) {
            page_visit_service.add({
                report_name: page_title,
                report_url: url,
                app_name: constants.app,
                state_change: state_change.toString()
    
               //operator_icao_code: "AAA"
            }).then((data) => {
                return {data: data};
            }).catch((err) => {
                return {err: err};
            });
        }
    }

    static append(param, value, page_title) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let param_array = [];
        for (const p of params) {
            if (p[0] !== 'query') {
                var p2 = p[0];
                if (p[1] !== "") {
                    p2 += "=" + p[1];
                }
                param_array.push(p2);
            }
        }
        params = "";
        if (param_array.length) {
            params = "&" + param_array.join('&');
        }

          console.log("URL - pushState (appebd)", {
            report_name: page_title
           //operator_icao_code: "AAA"
        });
        this.pushState("", null, "?" + param + "=" + value + params, page_title);
    }
}