import { Auth, API, Analytics, Logger } from 'aws-amplify';
//Analytics.configure({ disabled: true })
const logger = new Logger('BaseService')




class BaseService {

    constructor(apiName, collectionPath) {
        logger.debug(apiName, collectionPath);
        this.apiName = apiName;
        this.collectionPath = collectionPath;
        // set the permissions based on the acl_roles submitted.
        this.permissions = [];
      
        logger.debug("permissions", this.collectionPath, this.permissions);
        
        this.acl_roles = [];
    }
    
    setPermissions(acl_roles) {
        
        this.acl_roles = acl_roles;
        var resources = ["operations|", "operations" + this.collectionPath + "|"];
        acl_roles.forEach((element) => {
            resources.forEach((resource) => {
                logger.debug("permissions1", resource, element, element.substring(0, resource.length));
                if (resource == element.substring(0, resource.length)) {
                    var permissions = element.substring(resource.length);
                    permissions = permissions.replace("w", "cud");
                    permissions = permissions.replace("p", "crudp");
                    let i = permissions.length;
                    while (i--) {
                        if (!this.permissions.includes(permissions.charAt(i))) 
                            this.permissions.push(permissions.charAt(i));
                    }
                }
            });
        });
        logger.debug("setPermissions", acl_roles, this.permissions);
    }
    
    readOnly() {
        logger.debug("readOnly", this.acl_roles,"operations" + this.collectionPath);
        if (Array.isArray(this.acl_roles) && this.acl_roles.includes("operations" + this.collectionPath)) {
             return false;
        }
        else return true;  
    }
    
    async getAuthorizationHeader() {
        let currentSession = await Auth.currentSession();
        return currentSession.idToken.jwtToken;
    }

    async fetchRecords(query, header) {
        logger.debug("fetchRecords 1",  this.apiName, query);
        let path = this.collectionPath;

        let headers = {
            Authorization: await this.getAuthorizationHeader(),
            "Content-Type": "application/json",
            //"Cache-Control": 'no-cache',
            //Accept: "application/json"  
        };
        headers = {...headers, ...header};
        logger.debug("headers", headers);
        

        let options = { headers: headers, queryStringParameters: query };
        
        logger.debug("fetchRecords 2",  this.apiName, path, options);
        var result = this.makeGetApiCall(this.apiName, path, options);
        logger.debug("fetchRecords 3",  result);
        return result;

    }

    async fetch(recordId, params={}) {

        let path = this.collectionPath + '/' + recordId;

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };

        let options = {
            headers: headers,
            queryStringParameters: params
        };

        return this.makeGetApiCall(this.apiName, path, options);
    }


    async add(payload) {

        let path = this.collectionPath;

        let headers = {
            "Content-Type": "application/json",
            Authorization: await this.getAuthorizationHeader()
        };

        let options = {
            body: payload,
            headers: headers
        };

        return this.makePostApiCall(this.apiName, path, options);
    }

    async update(recordId, payload) {
        logger.debug("update1", recordId, payload);
        let path = this.collectionPath + '/' + recordId;

        let headers = {
            "Content-Type": "application/json",
            Authorization: await this.getAuthorizationHeader()
        };

        let options = {
            body: payload,
            headers: headers
        };
        logger.debug("update2", this.apiName, path, options);
        return this.makePutApiCall(this.apiName, path, options);
    }

    async deleteRecord(recordId) {

        let path = this.collectionPath + '/' + recordId;

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };

        let options = { headers: headers };
       
        return this.makeDeleteApiCall(this.apiName, path, options);

    }


    async makeGetApiCall(apiName, path, options) {
        logger.debug(`making GET call to ${apiName} api with path: ${path}`);
        logger.debug("GET call options", options);


        try {
            return await API.get(apiName, path, options);
        }
        catch (e) {
            logger.debug("GET err", e, apiName, path, options);
            logger.error(e);
            throw e;
        }

    }

    async makePostApiCall(apiName, path, options) {
        logger.debug(`making POST call to ${apiName} api with path: ${path}`);
        logger.debug("POST call options", options);

        this.publishAction('POST', apiName, path, options);

        try {
           return await API.post(apiName, path, options);
        }
        catch (e) {
            logger.error(e);
            throw e;
        }

    }

    async makePutApiCall(apiName, path, options) {
        logger.debug(`making PUT call to ${apiName} api with path: ${path}`);
        logger.debug("PUT call options", options);

        this.publishAction('PUT', apiName, path, options);

        try {
           var result = await API.put(apiName, path, options);
           logger.debug(result);
           return result;
        }
        catch (e) {
            logger.error(e);
            logger.debug("makePutApiCall error",e);
            throw e;
        }

    }

    async makeDeleteApiCall(apiName, path, options) {
        logger.debug(`making DELETE call to ${apiName} api with path: ${path}`);
        logger.debug("DELETE call options", options);

        this.publishAction('DELETE', apiName, path, options);

        try {
            return await API.del(apiName, path, options);
        }
        catch (e) {
            logger.error(e);
            throw e;
        }

    }

    publishAction(httpMethod, apiName, path, options) {

        logger.debug("publishAction");

        let actionLabel = '';
        switch (httpMethod) {
            case 'POST':
                actionLabel = 'Add Record';
                break;
            case 'PUT':
                actionLabel = 'Update Record';
                break;
            case 'DELETE':
                actionLabel = 'Delete Record';
                break;
        }

        if (actionLabel.length > 0) {

            try {
                Analytics.record({
                    name: actionLabel,
                    attributes: {
                        'apiName': apiName,
                        'collection': this.collectionPath
                    }
                });
            }
            catch (e) {
                logger.error(e);
                logger.debug("publishAction error",e);
                throw e;
            }
        }
    }
}

export default BaseService;
