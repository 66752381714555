import React, { useState }  from 'react';
//import ReactDOMServer       from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';
import { useTheme }         from '@material-ui/core/styles';
import useMediaQuery        from '@material-ui/core/useMediaQuery';

import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogTitle          from '@material-ui/core/DialogTitle';
import TextField            from '@material-ui/core/TextField';
//import FormControlLabel     from '@material-ui/core/FormControlLabel';
//import Switch               from '@material-ui/core/Switch';
import Typography           from '@material-ui/core/Typography';
import Accordion            from '@material-ui/core/Accordion';
import MuiAccordionSummary  from '@material-ui/core/AccordionSummary';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
//import Tooltip              from '@material-ui/core/Tooltip';

import MultiSelect          from './FormElements/MultiSelect';
import Select               from './FormElements/Select';
import BooleanElement       from './FormElements/Checkbox';
import QueryBuilder         from './FormElements/QueryBuilder';
//import OperatorService from "../services/OperatorService";
//import { getIcon } from "../data/Operators";

const logger = new Logger('FormDialog');


const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(100, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

function processColumn(data, column, error_columns, handleChange, read_only) {
    /*
    if (read_only) {
                        if (child.) {
                            child.readonly = true;
                        }
                        else if (child.formType !== "hidden" && child.formType !== "anchor") {
                            child.formType = "readonly";
                        }

                    }
    */
    logger.debug("processColumn0", read_only, column.field, data, column, error_columns);

    logger.debug("processColumn1", column.field, data, column, error_columns);
    var label = column.label ? column.label : column.headerName;
    var formContent = column.formContent;
    var href = column.href;
    var formContentStyle = column.style;
    var formType = column.formType;
    if (read_only && (formType !== "hidden" && formType !== "anchor" && formType !== 'boolean')) {
        formType = "readonly";
    }
   
    // define the header name by a function (may want to make this generic to all column types)
    if (typeof column.headerName  === 'function') {
        label = column.headerName(data);
    }
    if (typeof formContent  === 'function') {
        formContent = formContent(data);
    }
    if (typeof formContentStyle  === 'function') {
        formContentStyle = formContentStyle(data);
    }
     if (typeof href  === 'function') {
        href = href(data);
    }

    var error = false;

    var key = column.key ? column.key : (column.name ? column.name : column.field);
    var id = key;
    var name = column.name ? column.name : column.field;
    var field = column.field;
    var disabled = column.readonly;
    if (read_only) disabled = true;
    var helperText = column.helperText;



    if (Array.isArray(helperText)) { // if the helperText is an array, put breaks in between each element
        helperText = helperText.map((l, i) => (
            <React.Fragment key={i}>{l}{i < (helperText.length - 1) ? <br /> : ''}</React.Fragment>
        ));
    }
    var column_width = column.width * 2;

    error_columns.forEach(function(error_msg, index) {
        logger.debug("processColumn error_columns", error_msg['key'], key);
        if (error_msg['key'] == key) {
            error = true;
            helperText = error_msg['message'];
        }
    });
    var value=data[field];
    
    /*
    if (column.primaryKey && value && props.editType !== "new") {
        logger.debug("processColumn0", "read_only");
        formType = "readonly";
    }
    */

    if (formType === "readonly") {
        const DarkerDisabledTextField = withStyles({
          root: {
            marginRight: 8,
            "& .MuiInputBase-root.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
            },
             "& .MuiInputLabel-root.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
            }
          }
        })(TextField);
        return(<DarkerDisabledTextField key={key} id={id} name={name} label={label} defaultValue={value} helperText={helperText} variant="outlined" disabled
            style={{paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else  if (formType === "text") {

        return(<TextField key={key} id={id} name={name} label={label} defaultValue={value} helperText={helperText} variant="outlined" required={column.required}
            disabled={column.disabled}
            onChange={handleChange}
            error={error}
            style={{paddingRight:20, paddingBottom: 10, width: column_width}}
            inputProps={column.inputProps}
            />);

    }
    else  if (formType === "multiline") {
        return(<TextField key={key} id={id} name={name} label={label} defaultValue={value} helperText={helperText} variant="outlined" multiline  minRows={3}
            required={column.required}
            disabled={column.disabled}
            onChange={handleChange}
            error={error}
            style={{paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else if (formType === 'integer') {
        return(
            <TextField key={key} id={id} name={name} label={label} defaultValue={value} helperText={helperText} variant="outlined" required={column.required}
                error={error}
                variant="outlined"
                onChange={handleChange}
                type="number" inputProps={{ min: 0, max: 60, inputMode: 'numeric', pattern: '[0-9]*' }}
                style={{paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else if (formType === 'float') {
        return(
            <TextField key={key} id={id} name={name} style={{width: 220}}  defaultValue={value} key={ column.field} label={label} helperText={helperText} variant="outlined"
                error={error}
                type="number" inputProps={{ min: 0, max: 60, inputMode: 'numeric' }}
                style={{paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else if (formType === 'boolean' || formType === 'bool') {
        logger.debug("Boolean", value, column);
        if (value === undefined) {
            value = column.defaultValue;
        }
        return(
            <BooleanElement key={key} id={id} name={name} value={value} label={label} width={column.width}  handleChange={handleChange} disabled={disabled}/>
            // <FormControlLabel control={<Switch color="primary" defaultChecked={value} id={ column.field} />}  label={label} style={{paddingTop:20}} />

        );
    }
    else if (formType === 'select') {
        logger.debug("select1", column.service, value, data, column.values, field);
        var defaultValue;
        if (column.getValue) {
            defaultValue = column.getValue(data);
        }
        var options = column.options;
        if (!options) options = [defaultValue];
        if (column.getValue) {
            value = column.getValue(data);
        }
        logger.debug("select2", options);
        return(<Select key={key} id={id} name={name} field={field} label={label} defaultValue={defaultValue} data={data} variant="outlined" required={column.required}
            helperText={helperText}
            service={column.service}
            handleChange={handleChange}
            error={error}
            disabled={column.disabled}
            options = {options} // this sets the select options to be the defaut value so it shows up
            getOptionLabel={column.getOptionLabel}
            renderOption={column.renderOption}
            style={{paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else if (formType === 'multiselect') {
        logger.debug("multiselect1", column.service, value);
        if (column.getValue) {
            value = column.getValue(data);
        }
        logger.debug("multiselect2", column.service, value);
        return(<MultiSelect key={key} id={id} name={name} field={field} label={label} defaultValue={value} data={data} variant="outlined" required={column.required}
            service={column.service}
            helperText={helperText}
            handleChange={handleChange}
            error={error}
            disabled={column.disabled}
            options={column.options}
            getOptionLabel={column.getOptionLabel}
            getInitValue={column.getInitValue}
            renderOption={column.renderOption}
            style={{display: 'inline-flex', paddingRight:20, paddingBottom: 10, width: column_width}} />);
    }
    else if (formType === 'querybuilder') {
        return  <div key={key} style={{border: "solid 1px #C4C4C4", paddingTop: 12, paddingLeft: 12, paddingBottom: 10, width: column_width - 20}} >
            <div style={{display: 'block'}}>{label}</div>

            <div style={{display: 'block'}}>
                <QueryBuilder name={name} value={value} handleChange={handleChange} ></QueryBuilder>
            </div>
        </div>;
    }
    else if (formType === "regexTest") {
        return(<Button key={key}
                variant="contained"
                onClick= {(e) => {
                    console.log("button click", e);
                }}
                style={{marginBottom: 20, width: column_width}}
                >Text Regex</Button>);
    }
    else if (formType === 'anchor' && href) {
        return(<a key={key} href={href} target={column.target}>{column.label}</a>);
    }

    else if (formType === 'content') {
        return(<p key={key} style={formContentStyle}>{formContent}</p>);
    }
    else if (formType === 'icon') {
        helperText = 
            <React.Fragment>
                <a href="https://marella.me/material-symbols/demo/" target="_blank">Click here to see icon list</a>
            </React.Fragment>;
        return(
            <React.Fragment key={key}>
                <TextField key={key} id={id} name={name} label={label} defaultValue={value} helperText={helperText} variant="outlined" required={column.required}
                    disabled={column.disabled}
                    onChange={handleChange}
                    error={error}
                    style={{paddingRight:4, paddingBottom: 10, width: column_width-80}}
                    inputProps={column.inputProps}
                />
                <span class="material-symbols-outlined" style={{paddingLeft: "3px", overflow: "hidden", 
                        height: "56px", width: "60px", color: "#1c3f62", fontSize: 50, 
                        border: "dashed 2px gray", marginRight: "20px", fontWeight: 300}}>{value}</span>
            </React.Fragment>
        );
    }
}

export default function FormDialog(props) {
    logger.debug("props", props, props.data);
    var data = props.data;
    if (!data) data = {};
    const [values, setValues] = useState(data);
    const [open, setOpen] = useState(false);

    const [expandedCols, setExpandedCols] = React.useState({});


    const theme = useTheme();
    const fullwidth = useMediaQuery(theme.breakpoints.up('md'));
    logger.debug("matches theme1", fullwidth);



    var title = "Editing an Existing Record";
    var content = "You are editing an existing record";
    if (props.editType === "new") {
        title = "Create a New Record";
        content = "You are adding a new record";

        data = {};
    }
    else if (props.editType == "clone") {
        title = "Cloning a Record";
        content = "You are adding a new record from an existing record";
    }
    else if (props.editType == "edit" && data === null) {
        //data = {}; // this shoudn't happen but apparently it is
    }
    var read_only = props.readOnly?props.readOnly:false;

    if (typeof props.service.save !== "function") read_only = true;

    if (props.permissions && !read_only) {

        read_only = true;
        logger.debug("props read_only1", props.permissions, data['published']);

        if ((props.editType === "new" || props.editType === "clone") && (props.permissions.includes('c') || props.permissions.includes('p'))) {
            read_only = false;
            logger.debug("props read_only2", read_only);
        }
        if (props.editType === "edit" && (props.permissions.includes('u') || props.permissions.includes('p'))) {
            if (data['published'] === true && !props.permissions.includes('p')) {
                read_only = true;
                logger.debug("props read_only4", read_only, data['publish'], props.permissions.includes('p'));
            }
            else {
                read_only = false;
            }
        }
    }

    if (read_only) {
        title = "Viewing Record";
        content = "You are viewing the selected record";
    }
    logger.debug("props read_only5", read_only);

    if (props.permissions) {
        props.columnDefs.forEach(function(column, index) {
            if (column.children) {
                column.children.forEach(function(child, child_index){
                    if (props.editType === "new" && typeof child.defaultValue !== 'undefined') {
                        data[child.field] = child.defaultValue;
                    }
                });
            }
        });
    }

    if (props.permissions && !props.permissions.includes('c') && !props.permissions.includes('u')) {
        title = "Data Record Details";
        content = null;

    }
    if (props.open != open) {
        logger.debug("props1", data);
        setValues(data);

        var expanded = {};
        props.columnDefs.forEach(function(column, index) {
            var expand = column.defaultExpanded;
            if (props.newRecord) expand = true;
            expanded[column.headerName] = expand;
            if (!props.newRecord && props.groupHeaderName === column.headerName) expanded[column.headerName] = true;
        });
        setExpandedCols(expanded);
        setOpen(props.open);
    }

    const handleChange = (event) => {
        logger.debug('handleChange1', event, event.target.name, event.target.value, values);

        var new_values = values;
        new_values[event.target.name] = event.target.value;

        logger.debug('handleChange2', new_values);
        setValues(new_values);
        // Set the error message.
        var column_errors = [];
        logger.debug('handleChange2', column_errors);
        error_message.columns.forEach((value) => {
            if (value['key'] !== event.target.name) {
                column_errors.push(value);
            }
        });
        var message = error_message.message;
        if (column_errors.length === 0) {
            message = "";
        }
        setErrorMessage({message: message, columns: column_errors});
    };

    const [error_message, setErrorMessage] = useState({message: "", columns: []});


    const handleAccordionChange = (panel) => (event, newExpanded) => {
        logger.debug('handleAccordionChange', panel, event, newExpanded);
        var new_expanded = {...expandedCols};
        new_expanded[panel] = newExpanded;
        setExpandedCols(new_expanded);
    };


    var handleClose = () => {
        setErrorMessage({message: "", columns: []});
        // reset the expanded panels
        var initExpanded = {};
        props.columnDefs.forEach(function(column, index) {
            initExpanded[column.headerName] = column.defaultExpanded | false;
        });
        setExpandedCols(initExpanded);
        logger.debug("handleClose", values, props.data);
        setValues(props.data);
        props.handleClose();
    };

    var handleSave = () => {
        logger.debug('handleSave1 values', values);
        var new_expanded = {}; // expand all sections that have errors
        var new_error = {message: "", columns: []};
        props.columnDefs.forEach(function(column, index) {
            if (column.children) {
                column.children.forEach(function(child, child_index){
                    var name = child.name ? child.name : child.field;
                    if (child.required && (typeof values[name] === "undefined"
                            || (typeof values[name] === "string" && values[name].trim() == ""))) {
                        logger.debug("required", values[name], typeof values[name]);
                        new_error['message'] = "The highlighted fields are required.";
                        new_error['columns'].push({key:name, message: "This value is required."});
                        new_expanded[column['headerName']] = true;
                    }
                });
            }
            else {
                if (column.required) {
                    var name = column.name ? column.name : column.field;
                    if (column.required && !values[name]) {
                        new_error['message'] = "The highlighted fields are required.";
                        new_error['columns'].push({key:name, message: "This value is required."});
                    }
                }
            }
        });

        setExpandedCols(Object.assign(expandedCols, new_expanded));

        logger.debug('handleSave2 required', new_error, new_expanded);
        if (!new_error.message) {
            logger.debug('handleSave2 values',values);

            props.service.save(values, props.metaData).then((response => {
               logger.debug("handleSave Response", response);
                if (response['success'] == true) {
                    logger.debug("handleSave response1", response);
                    if (response['updated'] !== false)
                        props.handleRefresh();
                    handleClose();
                }
                else {
                    logger.debug("handleSave failure", response);
                    if (response.response) {
                        logger.debug("handleSave response2", response, response.response, response.response.data.columns);
                        if (!response.response.data.columns) response.response.data.columns = [];
                        setErrorMessage({message: response.response.data.error, columns: response.response.data.columns});
                    }
                    else if (response.error) {
                        setErrorMessage({message: response.error.message, columns: []});
                    }
                    else {
                        logger.debug("handleSave response3", response, response.response);
                        if (response) {
                            response = response.toString();

                        }
                        else response = 'Error: Unknown';
                        setErrorMessage({message: response, columns: []});
                    }
                }
            }));
        }
        else {
            setErrorMessage(new_error);
        }
    };

    var sections =[];

    var elements = [];

    const getElements = (() => {
        if (!values) return;
        logger.debug("getElements1", props, data, values, error_message);
        var elements = [];
        props.columnDefs.forEach(function(column, index) {
            if (column.children) {
                var children = [];
                var section_error = false;
                column.children.forEach(function(child, child_index){
                    var hide = false;
                    if (child.formHide) {
                        if (typeof child.formHide == "function")
                            hide =  child.formHide(data);
                        else hide = child.formHide;
                    }
                    if (!hide) {
                        if (!fullwidth) {
                            child.max_width = true;
                        }
                        else {
                            child.max_width = false;
                        }
                        var key = child.key ? child.key : (child.name ? child.name : child.field);
                        if ((data[child.field] === null || data[child.field] === "") && (child.defaultValue || child.defaultValue === 0)) {
                            data[child.field] = child.defaultValue;
                        }
                        if (child.newline || !fullwidth) {
                            children.push(<p key={key + "_p"}></p>);
                        }
                        children.push(processColumn(values, child, error_message.columns, handleChange, read_only));
                        var name = child.name ? child.name : child.field;
                        logger.debug("section_error1", error_message, name);
                        error_message.columns.forEach(function(column, index) {
                            if (column['key'] == name) section_error = true;

                        });

                    }
                });
                var icon = '';
                if (column.icon && data["operator_icao_code"]) {

                    var img = "https://origins-assets.nextcloud.aero/operator-logos/logo-" + data["operator_icao_code"].toLowerCase() + ".png";
                    icon = <img src={img} style={{width: 24, height: 24, marginRight: 16 }} />;
                }
                logger.debug("section_error2", column);
                logger.debug("section_error3", section_error);

                var section_title = column.headerName;
                var columnExpanded = expandedCols[column.headerName];
                if (section_error) {
                    section_title = <span style={{color: "red"}}>{section_title}</span>;
                }
                sections.push (
                    <Accordion
                        key={column.headerName}
                        expanded={Boolean(columnExpanded)}
                        onChange={handleAccordionChange(column.headerName)}
                    >
                        <AccordionSummary
                          expandIcon={column.expanded ? '' : <ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                        {icon}

                          <Typography>{section_title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{paddingTop: 24}}>
                            <div style={{width: "100%"}}>
                            {children}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            }
            else {
                var key = column.key ? column.key : (column.name ? column.name : column.field);

                if ((data[column.field] === null || data[column.field] === "") && (column.defaultValue || column.defaultValue === 0)) {
                    data[column.field] = column.defaultValue;
                }

                if (column.newline || !fullwidth) {
                    sections.push(<p key={key + "_p"}></p>);
                }

                if (column.newline || !fullwidth) {
                    sections.push(<p key={key + "_p"}></p>);
                }
                sections.push(processColumn(values, column, error_message.columns, handleChange, read_only));
            }
        });
        return elements;

    });




    if (props.open && elements.length == 0) {

        elements = getElements();
    }


    logger.debug('data', data);


    logger.debug("elements", elements);

    var dialog_content_text;
    if (content) {
        dialog_content_text =
            <DialogContentText>
                {content}
            </DialogContentText>
    }


    var dialog_buttons = [];
    var close_text = "close";
    /*
    if (Array.isArray(props.permissions) && props.permissions.includes('p')) {
        close_text = "Cancel";

        dialog_buttons.push(<Button disabled={!publishActive} variant="contained" color="primary" onClick={handleSave}  type="submit">Publish</Button>);
        dialog_buttons.push(<div style={{flex: '1 0 0'}} />); // puts a space between this button and the other buttons

    }
    */
    //if (Array.isArray(props.permissions) && (props.permissions.includes('u') || props.permissions.includes('c')) ) {
    if (!read_only) {
        close_text = "Cancel";
        var save_button_text = "Update Record";
        if (props.editType == "new") save_button_text = "Add Record";
        else if (props.editType == "clone") save_button_text = "Clone Record";

        dialog_buttons.push(<Button key={save_button_text} variant="contained" color="primary" onClick={handleSave}  type="submit">{save_button_text}</Button>);
    }

    dialog_buttons.push(<Button variant="contained" onClick={handleClose}>{close_text}</Button>);
    var dialog_actions =  <DialogActions>{dialog_buttons}</DialogActions>;

    var error_display;
    if (error_message.message) {
        error_display = <p style={{fontSize: 16, color: "red", height: "16px"}}>{error_message.message}</p>;
    }

    return (
        <Dialog fullScreen={!fullwidth} open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} >
            <DialogTitle>{title} {error_display}</DialogTitle>
            <DialogContent>
                {dialog_content_text}
                {sections}
            </DialogContent>
            {dialog_actions}
        </Dialog>
    );
}
