/**
 *  Generic map with no view components
 */ 

import React, {useState, useContext}  from 'react';
import { Logger } from 'aws-amplify';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
 
import MapService      from '../services/MapService';

import { makeStyles } from '@material-ui/core/styles';
import AppContext           from '../pac-responsive-ui-framework/core/AppContext';
import Container            from '@material-ui/core/Container';
import Map    from 'components/Map';

import AppBar               from '@material-ui/core/AppBar';
import Toolbar              from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import Box                  from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButtonHome           from 'pac-responsive-ui-framework/header/IconButtonHome';

const logger = new Logger('FlightMap');

export default function FlightMap(props) {
  
  const { search } = useLocation();
  logger.debug("search", search);
  const values = queryString.parse(search);
  //logger.debug("values1", typeof values, {values});

  const app_context = useContext(AppContext);  
  logger.debug("app_context", app_context);
  
  values['jwtToken'] = app_context.state.user.jwtToken;
  
  var flight_id = values.flight_id; //.split(',');
  var update_map = true;
  var refresh_page=false;
  if (typeof(values.update_map) == "string") update_map = values.update_map.toLowerCase() != "no";
  if (typeof(values.refresh_page) == "string") refresh_page = values.refresh_page.toLowerCase() == "yes";

  if (refresh_page) {
    logger.debug("refresh page");
    window.parent.location.href = "http://google.com";
  }

  const setTitle = (values) => {
    var title = "Flight Map - ";
    var title_array = [];
    if (values.type) {
      title_array.push(values.type);
    }
    if (values.flight_id) {
      title_array.push("Flight ID: " + values.flight_id);
    }
    if (values.operator && values.operator != "All") {
      title_array.push("Operator: " + values.operator);
    }
    if (values.tail && values.tail != "All") {
      title_array.push("Aircraft: " + values.tail);
    }
    if (values.route && values.route != "All") {
      title_array.push("Route: " + values.route);
    }
    if (values.takeoff_time) {
      title_array.push("Departure: " + values.takeoff_time);
    }
    
    title+= title_array.join(' | ');
    
    title_array = [];
    if (values.flight_id) {
      title_array.push(values.flight_id);
    }
    if (values.operator && values.operator != "All") {
      title_array.push(values.operator);
    }
    if (values.tail && values.tail != "All") {
      title_array.push(values.tail);
    }
    if (values.route && values.route != "All") {
      title_array.push(values.route);
    }
    if (values.takeoff_time) {
      title_array.push(values.takeoff_time);
    }
    var tab_title = "Flight Map | ";
    tab_title+= title_array.join(' | ');

  
    // Since this is not a "view" I stole the title code from View.js
   
    app_context.setPageTitle(title);  
    window.document.title = tab_title + " - " + app_context.state.title;
  };

  console.log("FlightMap", flight_id, update_map);  
  const useStyles = makeStyles(theme => ({
      root: {
          flexGrow: 1,
          height: "100%", 
          width: "100%",
          position: "fixed", 
        },
        container: {
            paddingTop: 16,
        },
        appbar_root: {
            color: theme.palette.primary.contrastText, 
            backgroundColor: theme.palette.primary.dark,
            left: 0,
           
            flexGrow: 1,
            paddingRight: 0
          },
           toolBar: {
            margin: "auto",
            width: "100%",
            whiteSpace: "nowrap",
          },
          content: {
             paddingTop:64,
          }
  }));
  
  const [titleSet, setTitleSet] = useState(false);
  const [geoData, setGeoData] = useState(null);
  const [flightID, setFlightID] = useState(flight_id);

  var message = "No flights selected";
  var passData = geoData;
  if (update_map) {
    //console.log("FlightMap values ", geoData, {...values});
    //var flight_count = values.flight_id.split(",").length;
    if (geoData == null) {
      message= "Loading ...";
      const service = new MapService();
      service.fetchRecords({...values})
        .then((data) => {
            logger.debug("FlightMap data", data);  
            if (data['meta'].result_count > 0) { 
              var flight_id_array = [];
              for(var i = 0; i < data['data'].length; i++) {
                flight_id_array.push(data['data'][i].flight_metadata.FlightID);
              }
              console.log("FlightMap data flight_id_array", flight_id_array, flight_id);  
              if (flight_id_array.length == 1) {
                if (!values['type']) values['type'] = "Single Flight";
                if (!values['operator']) values['operator'] = data['data'][0]['flight_metadata']['Operator'];
                if (!values['route']) values['route'] = data['data'][0]['flight_metadata']['Route'];
                if (!values['tail']) values['tail'] =  data['data'][0]['flight_metadata']['Tail'];
                if (!values['takeoff_time']) values['takeoff_time'] = data['data'][0]['flight_metadata']['TakeoffTime'];
                
                setTitle(values);
              }
             
              setTitleSet(true);

              console.log("FlightMap data flight_id_array", flight_id_array, flight_id, values);  
              setFlightID(flight_id_array.join(','));
              setGeoData(MapService.parseData(data));
            }
            else {
               setGeoData(-2);
               if (0) {
                 var fs = require('../data/MapTestData/test3.json');
                 logger.debug(fs);
                  //var obj = JSON.parse(fs.readFileSync('file', 'utf8'));
                 
                 setGeoData(MapService.parseData(fs));
               }
            }
        }).catch((err) => {
          console.log("fetchRecords:error", err);  
          setGeoData(-1);
          
        });
    }
    else if (geoData == -1) {
      passData = null;
      message = 'Could not load flight data';
    }
    else if (geoData == -2) {
      passData = null;
      message = 'Could not find flight data';
    }
    else {
      // remove the beam layer and events and beam switches
      if (geoData.length -3 > 1) message= (geoData.length -3) + " flights loaded"; 
      else message= (geoData.length -3) + " flight loaded"; // remove the beam layer and events and beam switches
      /*
      if (values.route && values.route != "All" && values.tail && values.tail != "All") {
        message = message + " for route " + values.route + ' and aircraft ' + values.tail;
        title = "Map for route " + values.route + ' and aircraft ' + values.tail;
      }
      
      else if (values.route && values.route != "All") {
        message = message + " for route " + values.route;
        title = "Map for route " + values.route;
      
      }
      else if (values.tail && values.tail != "All") {
        message = message + " for aircraft " + values.tail;
        title = "Map for aircraft " + values.tail;
      
      }
      else if (values.operator && values.operator != "All") {
        message = message + " for operator " + values.operator;
        title = "Map for operator " + values.operator;
      }
      */
      
    }
  }
  else if (!update_map) {
    //message= "<p>Map is not being updated.</p><p>Please select Update Map to update the map data.</p>";
    message = "";
  }
  else {
    message= "No flights selected";
  }
  if (!titleSet) {
    setTitle(values);
  }
  /*
var title = "Flight Map - ";
var title_array = [];
if (values.type) {
  title_array.push(values.type);
}
if (values.flight_id) {
  title_array.push("Flight ID: " + values.flight_id);
}
if (values.operator && values.operator != "All") {
  title_array.push("Operator: " + values.operator);
}
if (values.tail && values.tail != "All") {
  title_array.push("Aircraft: " + values.tail);
}
if (values.route && values.route != "All") {
  title_array.push("Route: " + values.route);
}
if (values.takeoff_time) {
  title_array.push("Departure: " + values.takeoff_time);
}

title+= title_array.join(' | ');

title_array = [];
if (values.flight_id) {
  title_array.push(values.flight_id);
}
if (values.operator && values.operator != "All") {
  title_array.push(values.operator);
}
if (values.tail && values.tail != "All") {
  title_array.push(values.tail);
}
if (values.route && values.route != "All") {
  title_array.push(values.route);
}
if (values.takeoff_time) {
  title_array.push(values.takeoff_time);
}
var tab_title = "Flight Map | ";
tab_title+= title_array.join(' | ');

  
  // Since this is not a "view" I stole the title code from View.js
 
  app_context.setPageTitle(title);  
  window.document.title = tab_title + " - " + app_context.state.title;
  */
  const classes = useStyles();
  

  
  var overlay = null;
  if (typeof(values.url) == "string") {
    var url;
    url = values.url + "?";
    
    var param_map = JSON.parse(values.param_map);
   // logger.debug('param_map1', values.param_map, param_map);
    for (const v in values) {
      if (v != "url" && v != "flight_id" && v != "param_map") {
        var param = v;
        if (v in param_map) {
          param = param_map[v];
        }
        url = url + param + '=' + values[v] + "&";
      }
      console.log(`${v}: ${values[v]}`);
    }
    console.log("FlightMap  url1", url);  
    if ('flight_id' in param_map) {
      url = url + param_map['flight_id'] + '=' + flightID;
    }
    else {
      url = url + 'flight_id=' + flightID;
    }
    console.log("FlightMap  url2", url);  
    /*
    overlay = <div id='overlay' style={{
          zIndex:400, 
          position: 'fixed', 
          bottom: '24px', 
          right: '8px', 
          border: '2px solid #aeaeae', 
          backgroundColor: 'white',
          //fontSize: 24,
          borderRadius: 5,
          width: '32px',
          height: '32px',
          padding: "2px",
          //vAlign: "middle"
      }}><Tooltip title="Refresh Page">
          <IconButton size="small"  class="" onClick={() => {  window.parent.location.href = url; }} >
            <RefreshIcon/>
          </IconButton>
      </Tooltip></div>;
      */
  }
  return (
     <div className={classes.root}>
        <AppBar elevation={0} className={classes.appbar_root
        } position="fixed">

            <Toolbar>
              <IconButtonHome 
                title={app_context.state.title}
                logo=<img src={require("../assets/appbarlogo.png")} alt="logo" />
                icon=<img src={require("../assets/appbarlogomobile.png")} alt="logo" />
              />
              <Box pl={2}><Typography >{app_context.state.page_title}</Typography></Box>
            </Toolbar>
        </AppBar>
        <Map data={passData} message={message} />
    </div>
          
  );
}